import * as adminApi from '@/api/admin.js';

export default {
	namespaced: true,
	state: {
		title:null,
		text:null,
		segment:"all",
		segment_custom:[],
		users:[],
		messages:[],
		page:1,
		total_items:0, 
	},
	getters: { 
		admin_push:state=>{
			return state
		},  
	},
	mutations: {
		setMessages(state, messages){ 
			state.messages = messages; 
		},  
		setUsers(state, users){ 
			state.users = users; 
		},  
		setTitle(state, title){
			state.title = title; 
		},
		setText(state, text){
			state.text = text; 
		},
		setSegment(state, segment){
			state.segment = segment;
		}, 
		setSegmentCustom(state, user){
			state.segment_custom = user;
		},
		setPage(state, page){
			state.page = page;
		},
		setTotalItems(state, items){
			state.total_items = items;
		},
	},
	actions: {    
		async messagesGet({ state, commit }){ 
			await adminApi.pushMessagesGet({page:state.page}).then((response)=>{
				if(response.result){
					commit('setMessages', response.messages);
					commit('setUsers', response.users);
					commit('setTotalItems', response.total_items);		 				 
				}
			});	  
		},
		async pushMessageSend({ state, commit, dispatch }){

			await adminApi.pushMessageSend({
				title:state.title,
				text:state.text,
				segment:state.segment,
				segment_custom:state.segment_custom,
			}).then((response)=>{
				if(response.result){
					commit('setTitle', null);
					commit('setText', null);
					commit('setSegment', 'all');
					commit('setSegmentCustom', []);
					dispatch("messagesGet");
				}
			});	  
		}, 
		async pushMessageDelete({ dispatch }, data){
			await adminApi.pushMessageDelete(data).then((response)=>{
				if(response.result){ 
					dispatch("messagesGet");
				}
			});
		}
	}, 
}