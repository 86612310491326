<template>
	<div class="modal-account-project" :style="device.type =='mobile' ? { 'height': '100%' } : { 'max-width': '717px', 'max-height': '650px', 'min-height': '300px'}">
    <div class="modal-close">
      <mdicon name="close-circle-outline" 
        class="modal-close-icon" 
        :style="device.type =='mobile' ? { 'right': '15px', 'top': '15px' } : {}"
        :width="30" 
        :height="30" 
        @click="close()"
      />
    </div>
    <div class="modal-content">
      <div style="max-width: 250px;display: flex;flex-direction: column;">
        <div style="font-size: 15px; text-transform: uppercase; font-weight: 500; margin-bottom: 20px;">Сохранить проект</div>
        <div class="welcome-btn" @click="saveImage('upload')" style="width: auto;">
          <mdicon name="upload-box-outline" /> 
          Сохранить проект в галерею
        </div>
        <div class="welcome-btn" style="width: auto;" @click="saveImage('price')">
          <mdicon name="currency-rub" /> 
          Сохранить и узнать стоимость
        </div>
        <div class="welcome-btn" @click="backWelcome()" style="width: auto;">
          <mdicon name="plus-circle-outline" /> 
          Начать новый проект
        </div>
      </div>
    </div>
  </div>
</template>
<script> 
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { config, pushModal, popModal } from "jenesius-vue-modal"
import html2canvas from "html2canvas";
import SetRequest from './set_request.vue'
import ViewResultProject from './view_result_project.vue'

export default {  
  computed:{   
    ...mapGetters("device", { device:"device" }),
    ...mapGetters("account", { account:"account" }),
    ...mapGetters("photo_object", { photo_object:"photo_object" }),
    ...mapGetters("account_templates", { account_templates:"account_templates" }),
    ...mapGetters("account_constructor", { account_constructor:"account_constructor" }),   
  },  
  methods:{    
    ...mapMutations("device", ["setContentLoad"]),
    ...mapMutations("photo_object", ["setProjectBase64", "setSaveStatus"]), 
    ...mapActions("account_templates", ["templatesGetBase64"]),
    ...mapActions("account_constructor", ["elementsGetBase64"]),
    ...mapActions("photo_object", ["saveProjectTemplate", "saveProjectConstructor"]),  
    close(){
      popModal()
    },  
    backWelcome(){
      popModal()
      this.$router.push({name:'account-welcome', params:{id:this.account.id}});
    },
    saveImage(type){
      if(type =='price' && !this.photo_object.project.save_status){
        config({ backgroundClose:false }) 
        pushModal(SetRequest, {
          account_name:this.account.name, 
          account_email:this.account.email, 
          account_phone:this.account.phone
        })
      }
      else{
        this.setContentLoad({ status:false, text:"Сохранение проекта" })
        if(this.photo_object.project.mode == 'template')
          this.saveImageTemplates(type)
        else
          this.saveImageConstructor(type)
      }
    },
    saveImageConstructor(type){
      let self = this       
      this.elementsGetBase64().then(async(response)=>{
        if(response.result){
          let photo_container = document.getElementById("photo_container");
          for(let key in this.account_constructor.templates)
            if(this.account_constructor.templates[key].length > 0)
              for(let key_e in this.account_constructor.templates[key]){
                document.getElementById(key+'_url_'+key_e).style.display = "none";
                document.getElementById(key+'_base64_'+key_e).style.display = "block";
              }
          await html2canvas(photo_container, {}).then(canvas => {
            let dataURL = canvas.toDataURL("image/jpg");
            if(dataURL !== "") {
              let elements = [];
              for(let key in this.account_constructor.templates)
                if(this.account_constructor.templates[key].length > 0)
                  for(let key_e in this.account_constructor.templates[key]){  
                    elements.push({
                      id:this.account_constructor.templates[key][key_e].id,
                      w:this.account_constructor.templates[key][key_e].w,
                      h:this.account_constructor.templates[key][key_e].h,
                      x:this.account_constructor.templates[key][key_e].x,
                      y:this.account_constructor.templates[key][key_e].y,
                    });
                  }
              self.setProjectBase64(dataURL);
              self.saveProjectConstructor({
                account_id:self.account.id,
                elements
              }).then((response)=>{
                if(response.result){
                  for(let key in self.account_constructor.templates)
                    if(self.account_constructor.templates[key].length > 0)
                      for(let key_e in self.account_constructor.templates[key]){
                        document.getElementById(key+'_url_'+key_e).style.display = "block";
                        document.getElementById(key+'_base64_'+key_e).style.display = "none";
                      }
                  self.save_share(dataURL, type)
                }
              })
            }
          });
        }
      })
    },
    saveImageTemplates(type){
      let self = this       
      this.templatesGetBase64().then(async(response)=>{         
        if(response.result){  
          let img_template_url = document.getElementById("img_template_url"); 
          let img_template_base64 = document.getElementById("img_template_base64");
          let photo_container = document.getElementById("photo_container");
          img_template_url.style.display = "none";
          img_template_base64.style.display = "block";
          await html2canvas(photo_container, {}).then(canvas => {  
            let dataURL = canvas.toDataURL("image/jpeg");
            if (dataURL !== "") {
              let sketch_width = self.account_templates.w_end == null ? self.account_templates.w : self.account_templates.w_end;
              let sketch_height = self.account_templates.h_end == null ? self.account_templates.h : self.account_templates.h_end;
              let sketch_x = self.account_templates.x;
              let sketch_y = self.account_templates.y;
              self.setProjectBase64(dataURL);
              self.saveProjectTemplate({
                account_id:self.account.id, 
                sketch_id:self.account_templates.select_template.id,
                sketch_width,
                sketch_height,
                sketch_x,
                sketch_y
              }).then(async (response)=>{
                if(response.result){
                  img_template_url.style.display = "block";
                  img_template_base64.style.display = "none";
                  self.save_share(dataURL, type)                  
                }
              })
            }
          });
        }
      })
    },
    async save_share(dataURL, type){
      this.setSaveStatus(false)
      this.setContentLoad({ status:true, text:null })

      let a = document.createElement('a')
      a.download = "project"; 
      
      if(this.device.type == 'mobile'){ 
        a.href = "https://apiapp.pzn.su/storage/"+this.account.id+"/"+this.photo_object.project.id+"/project.png"; 
 
        //a.target = "_blank";
        // const blob = await (await fetch(dataURL)).blob();
        // const file = new File([blob], 'project.jpg', { type: blob.type }); 
        // if(navigator.canShare({files: [file] }))
        //   navigator.share({files: [file] })
        // else
        //   a.click() 
      }
      else{
        a.href = dataURL;
      }

      if(this.device.os == 'Android')
          pushModal(ViewResultProject, {
            url:"https://apiapp.pzn.su/storage/"+this.account.id+"/"+this.photo_object.project.id+"/project.png",
          })
      else
        a.click()
      
      if(type =='price'){
        config({ backgroundClose:false })
        pushModal(SetRequest, {
          account_name:this.account.name,
          account_email:this.account.email,
          account_phone:this.account.phone
        })
      }
    } 
  },
}
</script>