export default {
	namespaced: true,
	state: {
		link_back:{
			status:false,
			to:null,
			page:null
		},
		link_next:{
			status:false,
			to:null,
			page:null
		}
	},
	getters: { 
		header_app:state=>{
			return state
		},  
	},
	mutations: {
		setLinkNullable(state){
			state.link_back = {
				status:false,
				to:null,
				page:null,
			}
			state.link_next = {
				status:false,
				to:null,
				page:null,
			}
		},
		setLinkBack(state, data){ 
			state.link_back = {
				status:data.status,
				to:data.to,
				page:data.page,
			}
		}, 
		setLinkNext(state, data){ 
			state.link_next = {
				status:data.status,
				to:data.to,
				page:data.page,
			}
		}, 
	},
	actions: {
		setLinkNext({ commit }, data){
			commit("setLinkNext", data)
		}
	}, 
}