export default function account ({ next, to, store, params, nextMiddleware }){
  
  let id = store.getters['account/account'].id;

  if(
    id !== "demo" &&
    (isNaN(Number(params.id)) || id !== Number(params.id))
  ) return next({ name: 'account-welcome', params:{id} })

  if(to.name == "account-index") return next({ name: 'account-welcome', params:{id} })
 
  return nextMiddleware()
 }