<template>
	<div class="modal-account-project" :style="device.type =='mobile' ? { 'height': '100%' } : { 'max-width': '717px', 'max-height': '650px', 'min-height': '400px'}">
    
    <div class="modal-close">
      <mdicon name="close-circle-outline" 
        class="modal-close-icon" 
        :style="device.type =='mobile' ? { 'right': '15px', 'top': '15px' } : {}"
        :width="30" 
        :height="30" 
        @click="close()"
      /> 
    </div> 
    
    <div class="modal-content">
      
      <div style="max-width: 250px;display: flex;flex-direction: column;">
        <div style="font-size: 15px; text-transform: uppercase; font-weight: 500; margin-bottom: 20px;">
          <span v-if="type == 'save'">Для сохранения проекта зарегистрируйтесь</span>
          <span v-if="type == 'select_template'">Для использования этого шаблона зарегистрируйтесь</span>
        </div>
        
        <div class="d-block">

          <div class="p-relative m-tb-15">
            <input class="npt" type="text" placeholder="Имя" v-model="name" > 
            <div 
              class="error m-tb-5" 
              v-if="typeof(errors.name) != 'undefined' && errors.name !== null"
            >{{ errors.name[0] }}</div>
          </div>

          <div class="p-relative m-tb-15">
            <input class="npt" type="email" placeholder="Email" v-model="email"> 
            <div 
              class="error m-tb-5" 
              v-if="typeof(errors.email) != 'undefined' && errors.email !== null"
            >{{ errors.email[0] }}</div>
          </div>

          <div class="p-relative m-tb-15">
            <input class="npt" type="text" placeholder="Телефон" v-model="phone" v-mask="{ mask: '+7 (###) ###-####', raw: (v) => (raw = v) }"> 
            <div 
              class="error m-tb-15" 
              v-if="typeof(errors.phone) != 'undefined' && errors.phone !== null"
            >{{ errors.phone[0] }}</div>
          </div>

          <div class="p-relative m-tb-15">
            <input class="npt" type="password" placeholder="Пароль" v-model="password"> 
            <div 
              class="error m-tb-15" 
              v-if="typeof(errors.password) != 'undefined' && errors.password !== null"
            >{{ errors.password[0] }}</div>
          </div>

          <div class="m-tb-15" v-if="!load_status">
            <input 
              type="button" 
              class="btn btn-main" 
              value="Зарегистрироваться"
              style="text-transform: uppercase;"
              @click="register()"
            >
          </div>
 
        </div>
      </div>
      
    </div>
    
  </div>
</template>
<script> 
import * as userApi from '@/api/account.js';
import { mapGetters, mapActions } from 'vuex'
import { popModal, openModal, config } from "jenesius-vue-modal"
import AccountProject from './account_project.vue'

export default {  
  props:["type"],
  data () {
    return { 
      load_status:false,   
      name:null,
      email:null,
      phone:null,
      password:null, 
      errors:{}, 
    }
  }, 
  computed:{   
    ...mapGetters("getRoute", { getRoute:"getRoute" }),
    ...mapGetters("device", { device:"device" }),  
  },  
  methods:{     
    ...mapActions("account", ["loadToken", "loadUser"]),
    close(){
      popModal()
    },
    async register(){      

      this.errors = {};
      let check_status = true;

      if(this.name == null || this.name.trim().length < 2){
        check_status = false;
        this.errors = { name:["Слишком короткое имя"] };
      } 
      // eslint-disable-next-line
      if(check_status && (this.email == null || !this.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))){
        check_status = false;
        this.errors = { email:["Не корректный email"] };
      } 
      // eslint-disable-next-line
      if(check_status && (this.phone == null || !this.phone.match(/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im))){
        check_status = false;
        this.errors = { phone:["Не корректный телефон"] };
      }
      if(check_status && (this.password == null || this.password.length < 6)){
        check_status = false;
        this.errors = { password:["Пароль менее 6 символов"] };
      }

      if(check_status){

        let params = {
          name:this.name,
          email:this.email,
          phone:this.phone,
          password:this.password,
          device:{
            browser:this.device.browser,
            browser_v:this.device.browser_v,
            os:this.device.os,
            type:this.device.type
          }
        }

        this.load_status = true;

        await userApi.register(params).then(async (response)=>{      
          if(response.result){ 
            localStorage.setItem('token', response.token); 

            await this.loadToken().then( async (result) => { 
              if(result) {
                await this.loadUser();
                this.$router.push({name:this.getRoute.to.name, params:{id:response.account_id}, replace: true});
                this.load_status = false;
                config({ backgroundClose:false })
                openModal(AccountProject)
              }     
            });           
          }  
        }).catch(errors=>{ 
          this.errors = errors.response.data.errors 
          this.load_status = false;
        }); 
      }
    }   
  },
}
</script>