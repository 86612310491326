<template>
	<div 
      class="onboarding-content" 
      :style="style">
      <div v-html="text" style="font-size: 16px;line-height: 23px;"></div>
      <div class="onboarding-next-btn" @click="setValOnBoarding({ step:step, key:element, status:true})">Далее</div>
    </div>
</template>
<script> 
import { mapActions } from 'vuex'
export default {  
  props:["step", "element"],
  data () {
    return {}
  },
  computed:{
    style(){
      let style = {};
      if(this.step == "welcome" && this.element == "start") style = {};
      if(this.step == "welcome" && this.element == "photo") style = {'top':'-164px'};
      if(this.step == "welcome" && this.element == "gallery") style = {'top':'-164px'};
      if(this.step == "welcome" && this.element == "project") style = {'top':'-164px'};
      if(this.step == "photo" && this.element == "rotate") style = {'top':'-120px', 'margin-left':'auto', 'margin-right': 'auto', 'left': '0', 'right': '0'}; 
      if(this.step == "photo" && this.element == "mode") style = {'top':'-120px', 'margin-left':'auto', 'margin-right': 'auto', 'left': '0', 'right': '0'}; 
      if(
        (this.step == "photo" && this.element == "back") || 
        (this.step == "template" && this.element == "back") ||
        (this.step == "constructor" && this.element == "back")
      ) style = {'top':'74px'};
      if( 
        (this.step == "template" && this.element == "next") ||
        (this.step == "constructor" && this.element == "next")
      ) style = {'top':'74px', 'right':0, 'z-index': '9999'};
      if(
        (this.step == "template" && this.element == "select_template") ||
        (this.step == "constructor" && this.element == "select_template")
      ) style = {'top':'-110px', 'margin-left':'auto', 'margin-right': 'auto', 'left': '0', 'right': '0'};
      if(
        (this.step == "template" && this.element == "color") ||
        (this.step == "constructor" && this.element == "color")
      ) style = {'top':'-110px', 'margin-left':'auto', 'margin-right': 'auto', 'left': '0', 'right': '0'};
      if(
        (this.step == "template" && this.element == "all_template") ||
        (this.step == "constructor" && this.element == "all_template")
      ) style = {'top':'-110px', 'margin-left':'auto', 'margin-right': 'auto', 'left': '0', 'right': '0'};
      if(this.step == "constructor" && this.element == "element") style = {'top':'-110px', 'margin-left':'auto', 'margin-right': 'auto', 'left': '0', 'right': '0'};
      if(this.step == "constructor" && this.element == "confirm") style = {'top':'-110px', 'margin-left':'auto', 'margin-right': 'auto', 'left': '0', 'right': '0'};
      
      return style;
    },
    text(){
      let text = null;
      if(this.step == "welcome" && this.element == "start") text = "Добро пожаловать в конструктор Одежды сцены.<br> Для начала ознакомьтесь с функциями конструктора";
      if(this.step == "welcome" && this.element == "photo") text = "Нажав эту кнопку вы сможете сделать фото сцены и загрузить в приложение";
      if(this.step == "welcome" && this.element == "gallery") text = "Нажав эту кнопку вы сможете выбрать фото сцены и загрузить в приложение";
      if(this.step == "welcome" && this.element == "project") text = "Нажав эту кнопку вы сможете перейти к сохраненному ранее проекту";
      if(this.step == "photo" && this.element == "rotate") text = "Этими кнопками вы можете развернуть изображение";
      if(this.step == "photo" && this.element == "mode") text = "Эта кнопка позволяет выбрать режим работы:<br> Конструктор или Шаблоны, и перейти к дальнейшей работе";
      if(
        (this.step == "photo" && this.element == "back") || 
        (this.step == "template" && this.element == "back") ||
        (this.step == "constructor" && this.element == "back")
      ) text = "Кнопка для возврата назад"
      if( 
        (this.step == "template" && this.element == "next") ||
        (this.step == "constructor" && this.element == "next")
      ) text = "При помощи этой кнопки вы сможете<br> сохранить проект себе в галлерею, также узнать стоимость";
      if(
        (this.step == "template" && this.element == "select_template") ||
        (this.step == "constructor" && this.element == "select_template")
      ) text = "Здесь представлены готовые эскизы.<br> Нажмите на эскиз для его выбора"
      if(
        (this.step == "template" && this.element == "color") ||
        (this.step == "constructor" && this.element == "color")
      ) text = "Выберите цвет, чтобы отсортировать эскизы по цвету"
      if(
        (this.step == "template" && this.element == "all_template") ||
        (this.step == "constructor" && this.element == "all_template")
      ) text = "Эта кнопка открывает все эскизы"
      if(this.step == "constructor" && this.element == "element") text = "Выберите элемент, чтобы отсортировать эскизы по типу"
      if(this.step == "constructor" && this.element == "confirm") text = "Эта кнопка фиксирует собранный макет,<br> после чего вы сможете сохранить проект к себе в галлерею"

      return text;
    }
  },
  methods:{   
    ...mapActions("account", ["setValOnBoarding"]),
  } 
}
</script>