import * as accountConstructorApi from '@/api/account_constructor.js';
  
export default {
	namespaced: true,
	state: { 
		type_elements:[
			{ type:"arlekin", name:"Арлекин" },
			{ type:"zanaves", name:"Занавес" },
			{ type:"paduga", name:"Падуга" },
			{ type:"kulisa", name:"Кулисы" },
			{ type:"zadnik", name:"Задник" },
			{ type:"kabinet", name:"Кабинет" },
			{ type:"lambreken", name:"Ламбрекен" },
			{ type:"shtori", name:"Шторы" },
			{ type:"tul", name:"Тюль" },
			{ type:"dekor", name:"Декор" },
		],
		select_type_element:{
			type:null,
			name:null
		},
		colors:[''],
		select_color:'',
		elements:[],

		templates_element_edit:{
			type:null,
			key:null,
			draggable:null,
		},
		templates_config:{
			arlekin:{
				quantity:1,
				layer:100
			},
			zanaves:{
				quantity:1,
				layer:99
			},
			paduga:{
				quantity:10,
				layer:98
			},
			kulisa:{
				quantity:10,
				layer:97
			},
			zadnik:{
				quantity:1,
				layer:96
			},
			kabinet:{
				quantity:1,
				layer:95
			},
			lambreken:{
				quantity:1,
				layer:100
			},
			shtori:{
				quantity:1,
				layer:99
			},
			tul:{
				quantity:1,
				layer:98
			},
			dekor:{
				quantity:15,
				layer:100
			},
		}, 
		templates:{ 
			arlekin:[], 	 
			zanaves:[], 
			paduga:[],	 
			kulisa:[],	 
			zadnik:[],	 
			kabinet:[],	 
			lambreken:[],	 
			shtori:[],	 
			tul:[],	 
			dekor:[],	 
		} 
	},
	getters: {
		account_constructor:state=>{
			return state
		},  
	},
	mutations: {
		nullableTemplates(state){
			state.templates = { 
				arlekin:[], 	 
				zanaves:[], 
				paduga:[],	 
				kulisa:[],	 
				zadnik:[],	 
				kabinet:[],	 
				lambreken:[],	 
				shtori:[],	 
				tul:[],	 
				dekor:[],	 
			}; 
			state.templates_element_edit = {
				type:null,
				key:null,
				draggable:null,
			}
		},
		setSelectTypeElement(state, key){ 
			state.select_type_element.type = state.type_elements[key].type; 
			state.select_type_element.name = state.type_elements[key].name; 
		}, 
		setColors(state, colors){  
			state.colors = colors
		}, 
		setSelectColor(state, colors){
			state.select_color = colors
		},
		setElements(state, elements){
			state.elements = elements
		},
		setElementDraggable(state, data){
			state.templates[data.type][data.key].draggable = data.status
			state.templates_element_edit.draggable = data.status
		},
		setElementEdit(state, data){

			for(let key in state.templates){
				for(let k in state.templates[key])  
					state.templates[key][k].active = false
			}
			state.templates[data.type][data.key].active = true

			state.templates_element_edit.type = data.type
			state.templates_element_edit.key = data.key
			let draggable = data.type == null && data.key == null ? null : state.templates[data.type][data.key].draggable
			state.templates_element_edit.draggable = draggable
		},
		setAllElementTemplates(state, elements){
			for(let key in state.templates)
				for(let key_e in elements)
					if(key == key_e)
						state.templates[key] = elements[key_e];
			
			console.log(elements);
			console.log(state.templates);
		},
		setElementTemplates(state, data){

			let type = state.select_type_element.type;
			if(state.templates_config[type].quantity == 1 && state.templates[type].length > 0){
				if(state.templates[type][0].url !== data.url){
					state.templates[type][0].id = data.id;
					state.templates[type][0].url = data.url;
					state.templates[type][0].img_load = false; 	
				}			 
			}
			if(
				(state.templates_config[type].quantity > 1 && state.templates_config[type].quantity > state.templates[type].length) || 
				state.templates_config[type].quantity == 1 && state.templates[type].length == 0
			){
				state.templates[type].push({ 
					active:true,
					draggable:true,
					id:data.id,
					url:data.url,
					base64:null,
					x:100,
					y:100,
					w:100,
					h:100,
					img_load:false
				})
			}
		},
		spliceElementTemplates(state, data){
			let type = state.select_type_element.type;
			state.templates[type].splice(data.key, 1)
		},
		setWH(state, data){
			state.templates[data.type][data.key].w = data.w
			state.templates[data.type][data.key].h = data.h
		}, 
		setXY(state, data){
			state.templates[data.type][data.key].x = data.x
			state.templates[data.type][data.key].y = data.y
		},
		setElementBase64(state, data){ 
			for(let key_base in data)
				for(let key in state.templates) 
					for(let key_e in state.templates[key]){	

						console.log(key_base, state.templates[key][key_e].id)
						if(key_base ==  state.templates[key][key_e].id)
							state.templates[key][key_e].base64 = data[key_base]; 
					}	 
		},
		setLoadImgStatus(state, data){
			state.templates[data.type][data.key].img_load = data.status
		} 
	},
	actions: {    
		async constructorElementsGet({ state, commit, rootGetters }){
			
			let color = state.select_color == '' ? null : state.select_color;
			let user_id = rootGetters['account/account'].id

			await accountConstructorApi.constructorElementsGet({ 
				type:state.select_type_element.type, 
				color,
				user_id
			}).then((response)=>{
				if(response.result){
					commit('setElements', response.elements);
					commit('setColors', response.colors);
				}
			})	 
		},		 
		async setSelectTypeElement({ commit, dispatch }, key){			
			commit('setSelectTypeElement', key)
			dispatch('constructorElementsGet')		 
		},
		async elementsGetBase64({ state, commit }){
			
			let elements = [];

			for(let key in state.templates) 
				if(state.templates[key].length > 0)
					for(let key_e in state.templates[key])
						elements.push(state.templates[key][key_e].id)

			let response = await accountConstructorApi.constructorElementsBase64Get({ elements });
			if(response.result)
					commit('setElementBase64', response.base64);

			return { result: response.result }
		}, 
		setAllElementTemplates({ commit }, elements){
			commit('setAllElementTemplates', elements);
		} 
	}, 
}