import server from '@/api/http';

export async function usersGetAll(params){  
	let { data } = await server.get('admin/users/get/all', { params });
	return data;
}
export async function usersGet(params){  
	let { data } = await server.get('admin/users/get', { params });
	return data;
}
export async function usersSave(params){  
	let { data } = await server.get('admin/users/save', { params });
	return data;
} 
export async function usersDelete(params){  
	let { data } = await server.get('admin/users/delete', { params });
	return data;
}
export async function usersScetchGet(params){  
	let { data } = await server.get('admin/users/get/sketchs', { params });
	return data;
}


export async function sketchsGet(params){  
	let { data } = await server.get('admin/sketchs/get', { params });
	return data;
}  
export async function sketchsCreate(params){  
	let { data } = await server.post('admin/sketchs/create', params);
	return data;
}
export async function sketchsUp(params){  
	let { data } = await server.post('admin/sketchs/up', params);
	return data;
}
export async function sketchsDelete(params){  
	let { data } = await server.get('admin/sketchs/delete', { params });
	return data;
}

export async function sketchsColorsGet(params){  
	let { data } = await server.get('admin/sketchs/colors/get', { params });
	return data;
}
export async function sketchsColorCreate(params){  
	let { data } = await server.get('admin/sketchs/color/create', { params });
	return data;
}
export async function sketchsColorUp(params){  
	let { data } = await server.get('admin/sketchs/color/up', { params });
	return data;
}
export async function sketchsColorDelete(params){  
	let { data } = await server.get('admin/sketchs/color/delete', { params });
	return data;
}

export async function pushMessagesGet(params){  
	let { data } = await server.get('admin/push/messages/get', { params });
	return data;
}
export async function pushMessageGet(params){  
	let { data } = await server.get('admin/push/message/get', { params });
	return data;
}
export async function pushMessageDelete(params){  
	let { data } = await server.get('admin/push/message/delete', { params });
	return data;
}
export async function pushMessageSend(params){  
	let { data } = await server.get('admin/push/message/send', { params });
	return data;
}