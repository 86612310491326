<template> 
  <component :is="layout">  
    <router-view></router-view> 
  </component>  
  <widget-container-modal />
</template>
<script>
import { mapMutations } from 'vuex'
import DetectionDevice from '@/components/DetectionDevice.js'
import layout_page from '@/layouts/layout_page.vue'
import layout_account from '@/layouts/layout_account.vue'
import layout_admin from '@/layouts/layout_admin.vue'
import { markRaw } from "vue";
import { container } from "jenesius-vue-modal";

export default {
name: "AppLayout", 
  created() { 
      this.Init();
  }, 
  computed: { 
    layout(){  
        let layout = layout_page;
        if(this.$route.meta.layout == "page") layout = layout_page;
        if(this.$route.meta.layout == "account") layout = layout_account;
        if(this.$route.meta.layout == "admin") layout = layout_admin; 
        return markRaw(layout)
    }
  },
  methods: {
    ...mapMutations("device", [
      "setDevice", 
      "setAppInstallEvent", 
      "setAppInstallStatus", 
      "setAppIosStatus"
    ]),
    Init(){ 
     
      let detection = DetectionDevice.detection(this.detectBrowser);   
      this.setDevice(detection);

      if(detection.os == "iOS")
        this.setAppIosStatus(true);

      window.addEventListener('beforeinstallprompt', (e) => {   
        this.setAppInstallEvent(e);
        this.setAppInstallStatus(true);       
        e.preventDefault()
      }); 
    }
	},
  components: {
    WidgetContainerModal: container,
  },  
}
</script> 