import * as adminApi from '@/api/admin.js';

export default {
	namespaced: true,
	state: {
		users:[], 
		filter:{
			users:"all",
			custom:null
		},
		user_scetchs:[]
	},
	getters: { 
		admin_users:state=>{
			return state
		},  
	},
	mutations: {
		setUsers(state, users){ 
			state.users = users; 
		},  
		setfilterUsers(state, users){ 
			state.filter.users = users; 
		},
		setfilterCustom(state, custom){ 
			state.filter.custom = custom; 
		},
		setRole(state, data){
			state.users[data.key].role = data.role
		},
		setUpStatus(state, data){
			state.users[data.key].up_status = data.status
		},
		userScetchs(state, scetchs){
			state.user_scetchs = scetchs;
		}
	},
	actions: {    
		async usersGetAll({ state, commit }){ 
			await adminApi.usersGetAll({ filter:state.filter }).then((response)=>{
				if(response.result){
					commit('setUsers', response.users);	
				}
			});	  
		},  
		async usersSave({ state, dispatch }, key){ 

			await adminApi.usersSave({account_id:state.users[key].id, role:state.users[key].role}).then((response)=>{
				if(response.result){
					dispatch("usersGetAll");	
				}
			});	  
		},   
		async usersDelete({ dispatch }, data){ 
			await adminApi.usersDelete(data).then((response)=>{
				if(response.result){
					dispatch("usersGetAll");	
				}
			});	  
		},
		async usersScetchGet({ commit }, data){ 
			await adminApi.usersScetchGet(data).then((response)=>{
				if(response.result){
					commit('userScetchs', response.scetchs);		
				}
			});	  
		}, 
		setfilterUsers({ commit, dispatch }, users){ 
			commit('setfilterUsers', users);
			dispatch("usersGetAll");			
		}, 
		
	}, 
}