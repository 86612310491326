import { initializeApp } from "firebase/app"
import { getMessaging } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyARdSjTjClvrA0MgrhflR_sZ7A07k13DGM",
    authDomain: "pzn-app.firebaseapp.com",
    projectId: "pzn-app",
    storageBucket: "pzn-app.appspot.com",
    messagingSenderId: "251911360451",
    appId: "1:251911360451:web:23fee5ebe90130e519f776",
    measurementId: "G-TY5JW60KGC"
}
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// onMessage(messaging, (payload) => {
//     console.log("Message received. firebase.js ", payload); 
//     new Notification(
//         payload.notification.title,
//         payload.notification
//     );
// }); 
// onBackgroundMessage(messaging, (payload) => {
//     console.log("Message received. firebase.js ", payload);  
// }); 
 
 
export default messaging