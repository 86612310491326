<template>
	<div class="modal-account-project" :style="device.type =='mobile' ? { 'height': '100%' } : { 'max-width': '717px', 'max-height': '650px', 'min-height': '300px', 'height': '100%'}">
    
    <div class="modal-close">
      <mdicon name="close-circle-outline" 
        class="modal-close-icon" 
        :style="device.type =='mobile' ? { 'right': '15px', 'top': '15px' } : {}"
        :width="30" 
        :height="30" 
        @click="close()"
      /> 
    </div> 
    
    <div class="modal-content">
      
      <div style="max-width: 300px;width: 100%;display: flex;flex-direction: column;">
        <div style="font-size: 15px; text-transform: uppercase; font-weight: 500; margin-bottom: 20px;">Оставить заявку</div>

        <div class="p-relative" style="margin-bottom: 15px;">
          <input class="npt" type="text" placeholder="Имя" v-model="name" > 
          <div 
            class="error m-tb-5" 
            v-if="typeof(errors.name) != 'undefined' && errors.name !== null"
          >{{ errors.name[0] }}</div>
        </div>

        <div class="p-relative" style="margin-bottom: 15px;">
          <input class="npt" type="email" placeholder="Email" v-model="email"> 
          <div 
            class="error m-tb-5" 
            v-if="typeof(errors.email) != 'undefined' && errors.email !== null"
          >{{ errors.email[0] }}</div>
        </div>

        <div class="p-relative" style="margin-bottom: 15px;">
          <input class="npt" type="text" placeholder="Телефон" v-model="phone" v-mask="{ mask: '+7 (###) ###-####', raw: (v) => (raw = v) }"> 
          <div 
            class="error m-tb-15" 
            v-if="typeof(errors.phone) != 'undefined' && errors.phone !== null"
          >{{ errors.phone[0] }}</div>
        </div>
        
        <div>
          <div style="margin-bottom: 15px;">Габариты сцены:</div> 
          <div>
            <div class="p-relative" style="margin-bottom: 15px;">
              <input class="npt" type="number" placeholder="Высота сцены" v-model="height"> 
              <div 
                class="error m-tb-15" 
                v-if="typeof(errors.height) != 'undefined' && errors.height !== null"
              >{{ errors.height[0] }}</div>
            </div> 
            <div class="p-relative" style="margin-bottom: 15px;">
              <input class="npt" type="number" placeholder="Ширина сцены" v-model="width"> 
              <div 
                class="error m-tb-15" 
                v-if="typeof(errors.width) != 'undefined' && errors.width !== null"
              >{{ errors.width[0] }}</div>
            </div>
          </div> 
        </div>

        <div class="p-relative">
          <div style="margin-bottom: 15px;">Комментарии:</div> 
          <div style="margin-bottom: 15px;">  
            <textarea class="npt" rows="5" cols="20" placeholder="Ваш комментарий к заказу" v-model="comment"></textarea> 
          </div>
        </div>

        <div class="btn btn-main" style="text-transform: uppercase;padding: 13px 0px;" @click="setRequest()">
          Оставить заявку
        </div> 
      </div> 
    </div>

  </div>
</template>
<script> 
import * as projectApi from '@/api/project.js';
import { mapGetters, mapMutations } from 'vuex'
import { popModal } from "jenesius-vue-modal"
  
export default {  
  props:["account_name", "account_email", "account_phone"],
  data(){
    return{
      name:null,
      email:null,
      phone:null,
      height:null,
      width:null,
      comment:null,
      errors:{}
    }
  },
  mounted(){
    this.init()
  },
  computed:{   
    ...mapGetters("device", { device:"device" }),
    ...mapGetters("account", { account:"account" }), 
    ...mapGetters("photo_object", { photo_object:"photo_object" }),  
  },  
  methods:{   
    ...mapMutations("device", ["setContentLoad"]), 
    close(){
      popModal()
    },
    init(){
      this.name = this.account_name;
      this.email = this.account_email;
      this.phone = this.account_phone;
    },
    async setRequest(){

      let check = true;

      if(this.name == null || this.name.trim().length < 2){
        check = false;
        this.errors = { name:["Слишком короткое имя"] };
      } 
      // eslint-disable-next-line
      if(check && (this.email == null || !this.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))){
        check = false;
        this.errors = { email:["Не корректный email"] };
      } 
      // eslint-disable-next-line
      if(check && (this.phone == null || !this.phone.match(/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im))){
        check = false;
        this.errors = { phone:["Не корректный телефон"] };
      }
      if(check && this.height == null){
        check = false;
        this.errors = { height:["Введите высоту сцены"] };
      }
      if(check && this.width == null){
        check = false;
        this.errors = { width:["Введите ширину сцены"] };
      }

      if(check)
        await projectApi.setRequest({
          account_id:this.account.id,
          project_id:this.photo_object.project.id,
          name:this.name,
          phone:this.phone,
          email:this.email,
          height:this.height,
          width:this.width,
          comment:this.comment,
        }).then((response)=>{
          if(response.result){
            this.name = null;
            this.email = null;
            this.phone = null;
            this.height = null;
            this.width = null;
            this.comment = null;
            popModal()
          }
        }) 

    }     
  }
}
</script>