import server from '@/api/http';

export async function getProjects(params){  
	let { data } = await server.get('project/get/all', { params });
	return data;
}
export async function getProjectTemplate(params){  
	let { data } = await server.get('project/get_template', { params });
	return data;
}
export async function getProjectConstructor(params){  
	let { data } = await server.get('project/get_constructor', { params });
	return data;
}
export async function createProjectTemplate(params){   
	let { data } = await server.post('project/create_template', params);
	return data;
}
export async function upProjectTemplate(params){   
	let { data } = await server.post('project/up_template', params);
	return data;
}
export async function deleteProject(params){  
	let { data } = await server.get('project/delete', { params });
	return data;
}  
export async function setRequest(params){  
	let { data } = await server.get('requests/set', { params });
	return data;
}
export async function setVipRequest(params){  
	let { data } = await server.get('requests/vip/set', { params });
	return data;
}  
export async function createProjectConstructor(params){   
	let { data } = await server.post('project/create_constructor', params);
	return data;
}
export async function upProjectConstructor(params){   
	let { data } = await server.post('project/up_constructor', params);
	return data;
}