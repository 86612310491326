import server from '@/api/http';

export async function register(params){  
	let { data } = await server.get('register', { params });
	return data;
}
export async function login(params){  
	let { data } = await server.get('login', { params });
	return data;
}
export async function auth(){  
	let { data } = await server.get('auth');
	return data;
}
export async function logout(params){  
	let { data } = await server.get('logout', { params });
	return data;
}
export async function validToken(params){ 
	let { data } = await server.get('valid/token', { params });
	return data;
}
export async function passwordReset(params){ 
	let { data } = await server.get('reset_password', { params });
	return data;
}
export async function passwordVerify(params){ 
	let { data } = await server.get('verify_password', { params });
	return data;
}
export async function pushTokenSet(params){ 
	let { data } = await server.get('push_token/set', { params });
	return data;
}
export async function saveOnboarding(params){   
	let { data } = await server.post('onboarding/save', params);
	return data;
} 