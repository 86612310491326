export default function auth ({ next, store, nextMiddleware, params }){
  
  let isToken = store.getters['account/isToken'];

  if(params.id == "demo" && !isToken)
    store.dispatch('account/setDataUser', {
      id:'demo',
			role:'guest',
			name:null, 
			phone:null, 
			email:null, 
    })

  if(params.id == "demo" && isToken){
    let id = store.getters['account/account'].id;
    return next({ name: 'account-welcome', params:{id} })
  }
    
  if(params.id !== "demo" && !isToken) return next({ name: 'page-index'})
  
  return nextMiddleware()
 }