import * as accountApi from '@/api/account.js';

export default {
	namespaced: true,
	state: {
		token:null,
		id:null,
		role:null,
		name:null,
		phone:null,
		email:null,
		onboarding:null,
		view_onboarding:{
			status:false,
			element:null
		}
	},
	getters: {
		isToken: state =>{
			return state.token ? true : false;
		},		
		account:state=>{
			return state
		},  
	},
	mutations: {
		setToken(state, token){ 
			state.token = token; 
		}, 
		setDataUser(state, data){  
			state.id = data.id
			state.role = data.role 
			state.name = data.name 
			state.phone = data.phone 
			state.email = data.email
			state.onboarding = data.onboarding 
		}, 
		setStatusOnboarding(state, data){   
			state.view_onboarding.status = data.status
			state.view_onboarding.element = data.element
		},
		setValOnBoarding(state, data){ 
			state.onboarding[data.step][data.key] = data.status
		}
	},
	actions: {    
		async loadToken({ commit }){

			let token = localStorage.getItem('token') ? localStorage.getItem('token') : false; 

			if(token){ 
				let data = await accountApi.validToken({ token });	 
				if(!data.result) token = false; 
			} 
			
			commit('setToken', token);	

			return token;
		},
		async loadUser({ commit }){			 
			
			try { 
				let data = await accountApi.auth(); 	  
				commit('setDataUser', data);	
			} catch (error) {
				localStorage.removeItem('token')
				commit('setToken', false);	
			}  			 
		},
		setDataUser({ commit }, data){
			commit('setDataUser', data);
		},
		async setValOnBoarding({ state, commit, dispatch, rootGetters }, data){
			
			commit('setValOnBoarding', data);
			
			if(data.step == "template" && data.key == "all_template")
				dispatch("header_app/setLinkNext", { status:true, to:null, page:'account-template' }, { root:true });

			if(data.step == "template" && data.key == "next"){
				let select_template = rootGetters['account_templates/account_templates'].select_template;
				if(select_template.id == null)
					dispatch("header_app/setLinkNext", { status:false, to:null, page:null }, { root:true });
			}

			if(data.step == "constructor" && data.key == "confirm")
				dispatch("header_app/setLinkNext", { status:true, to:null, page:'account-constructor' }, { root:true });

			if(data.step == "constructor" && data.key == "next")
				dispatch("header_app/setLinkNext", { status:false, to:null, page:null }, { root:true });

			let formData = new FormData(); 
			formData.append('user_id', state.id);
			formData.append('onboarding', JSON.stringify(state.onboarding));

			await accountApi.saveOnboarding(formData).then((response)=>{
				if(response.result){ 
					dispatch("checkOnboarding", {step:data.step})
				}
			})			 
		},
		checkOnboarding({ state, commit, rootGetters }, data){ 
			
			let device_type = rootGetters['device/device'].type;

			for(let step in state.onboarding)  
				if(step == data.step){ 
					let status = false;
					let element = null;

					for(let key in state.onboarding[step]){					
						
						if(!state.onboarding[step][key]) {

							if(device_type =='desktop' && key == "photo") continue;
							else{
								status = true; 
								element = key
								break;
							}
						}
					}
 
					if(state.id !== "demo")
						commit('setStatusOnboarding', { status, element });
				} 
		} 
	}, 
}