<template>     
  <v-main class="layout-account">
      
      <div v-if="account.view_onboarding.status" 
        style="position: absolute;width: 100%;height: 100%;background: #020202;opacity: 0.5;z-index: 9;"
      ></div>

      <header-app></header-app>
      <router-view></router-view> 
      <div v-if="!device.content.load" class="loader-content">
        <span class="loader"></span>
        <div v-if="device.content.text !== null" class="loader-text">{{ device.content.text }}</div>
      </div>
  </v-main> 
</template>
<script>  
import * as userApi from '@/api/account.js';
import { getToken, isSupported, onMessage } from "firebase/messaging";
import { mapGetters, mapActions } from 'vuex'
import HeaderApp from '@/components/account/header_app.vue'

export default {
  created() {
      window.addEventListener("resize", this.resizeHandler),
      this.initNotification() 
  },
  mounted() {
    let self  = this
    setTimeout(() => {
      self.resizeHandler()
    },600);
  },
  unmounted() {
      window.removeEventListener("resize", this.resizeHandler) 
  },
  methods: {  
    ...mapActions("device", ["resizeHandler"]),   
    async initNotification(){
      
      if(this.account.id !== 'demo' && "Notification" in window && isSupported(this.$messaging)) {
        let permission = await Notification.requestPermission();
        console.log(permission);
        if(permission == "pending"){
          Notification.requestPermission().then((permissionResult) => {
            
            if(permissionResult === 'granted') {
               this.getPushToken();
            } 
          })
        } 
         
        if(permission == "granted")
          this.getPushToken();

          onMessage(this.$messaging, (payload) => {
            console.log("Message received. ", payload);
            new Notification(
                    payload.notification.title,
                    payload.notification
                );
          });
         
      }
    },
    getPushToken(){
      try {         
        getToken(this.$messaging, {
            vapidKey: "BP7u6SBmkegFQ-ZiRMyXl_MaTr5ARYvGoQGJzoitzQ3iNjNKZyiEp9VcjyE2WHKVvVj7giMpR7zRqXC2VvG_H9s"
        }).then((currentToken) => {  
            if (currentToken) {
              this.sendTokenToServer(currentToken);
            } else {
                console.warn("Failed to get token.");
            }
        }).catch((err) => { 
          console.log(err) 
        });
      } catch (e) {console.log(e);}
    },

    isTokenSentToServer(currentToken) {
      return (window.localStorage.getItem("sentFirebaseMessagingToken") === currentToken);
    },
    setTokenSentToServer(currentToken) {
        window.localStorage.setItem("sentFirebaseMessagingToken", currentToken ? currentToken : "");
    },
    async sendTokenToServer(currentToken) {
      let token = this.account.token;
      if(!this.isTokenSentToServer(currentToken)){             
         await userApi.pushTokenSet({ token, push_token: currentToken }).then((response)=>{
          if(response.result) {
              this.setTokenSentToServer(currentToken);
          }
        });
      }
    }, 
  },
  computed:{   
    ...mapGetters("device", { device:"device" }), 
    ...mapGetters("account", { account:"account" }), 
  },
  components: { 
    HeaderApp, 
  }, 
}
</script>