export default {
	namespaced: true,
	state: {
		to:null,
		from:null,
	},
	getters: {
		getRoute:state=>{
			return state
		}, 
	},
	mutations: { 
		setRoute(state, data){ 
			state.to = data.to; 
			state.from = data.from; 
		},  
	},
	actions: {
		setRoute({ commit }, data){ 
			commit('setRoute', data);  	  
		}, 
	}
}