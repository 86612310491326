import { createApp } from 'vue'
import App from './App.vue' 
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios' 
import VueInputMask from "@opentf/vue-input-mask";
import detectBrowser from "vue-detect-browser";
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js' 
import '@/styles/main.scss'
import { createMetaManager } from 'vue-meta' 
import VueDraggableResizable from 'vue-draggable-resizable'
import VueSidePanel from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css'
import VueFlicking from "@egjs/vue3-flicking";
import "@egjs/vue3-flicking/dist/flicking.css";
import firebaseMessaging from './firebase' 
import VueAwesomePaginate from "vue-awesome-paginate"; 
import "vue-awesome-paginate/dist/style.css";

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.directive("mask", VueInputMask.vMask);
app.use(mdiVue, { icons: mdijs })
app.use(detectBrowser)
app.use(createMetaManager())
app.component("vue-draggable-resizable", VueDraggableResizable)
app.use(VueSidePanel)
app.use(VueFlicking) 
app.config.globalProperties.$messaging = firebaseMessaging
app.use(VueAwesomePaginate)
app.mount('#app') 