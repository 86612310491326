import * as projectApi from '@/api/project.js';

export default {
	namespaced: true,
	state: {
		base64:null,
		file:null,
		project:{
			save_status:true,
			id:null,
			name:null,
			mode:null,
			base64:null
		},
		projects:[]  
	},
	getters: { 
		photo_object:state=>{
			return state
		},  
	},
	mutations: {
		nullablePhotoObject(state){
			state.base64 = null; 
			state.project.id = null;  
			state.project.name = null;  
			state.project.mode = null;  
		},
		setBase64(state, base64){ 
			state.base64 = base64;  
		}, 
		setFile(state, file){ 
			state.file = file;  
		}, 
		setProjectId(state, data){
			state.project.id = data.id;  
			state.project.name = data.name;   
		}, 
		setProjectMode(state, data){ 
			state.project.mode = data.mode;  
		}, 
		setProjects(state, projects){
			state.projects = projects;  
		},
		setProjectBase64(state, base64){ 
			state.project.base64 = base64;  
		},
		setSaveStatus(state, status){ 
			state.project.save_status = status;  
		},  
	},
	actions: { 
		async getProjects({ commit }, data){   

			let response = await projectApi.getProjects(data);
			if(response.result){
				commit("setProjects", response.projects)
			}

			return response;
		},
		async getProjectConstructor({ commit, dispatch }, data){   

			let response = await projectApi.getProjectConstructor(data);
			if(response.result){
				commit("setBase64", response.base64)  
				commit("setProjectId", { id:response.project_id, name:response.name})
				commit("setProjectMode", { mode:response.mode })
				dispatch("account_constructor/setAllElementTemplates", response.elements, { root:true })
				dispatch("header_app/setLinkNext", { status:true, to:null, page:'account-constructor' }, { root:true })
			}
			return response;
		},
		async getProjectTemplate({ commit, dispatch }, data){   

			let response = await projectApi.getProjectTemplate(data);
			if(response.result){
				commit("setBase64", response.base64)  
				commit("setProjectId", { id:response.project_id, name:response.name})
				commit("setProjectMode", { mode:response.mode })
				dispatch("account_templates/setSelectTemplateUrl", { id:response.sketch_id, url:response.sketch_url }, { root:true })
				dispatch("account_templates/setWH", { w:response.sketch_width, h:response.sketch_height }, { root:true })
				dispatch("account_templates/setWHEnd", { w_end:response.sketch_width, h_end:response.sketch_height }, { root:true })
				dispatch("account_templates/setXY", { x:response.sketch_x, y:response.sketch_y }, { root:true })
				dispatch("header_app/setLinkNext", { status:true, to:null, page:'account-template' }, { root:true })
			}
			return response;
		},
		async deleteProject({ dispatch }, data){    
			
			let response = await projectApi.deleteProject(data);
			if(response.result)
				dispatch("getProjects", {account_id:data.account_id}) 
		},
		async saveProjectTemplate({ state, commit }, data){
			
			let response = null;

			let formData = new FormData(); 
			formData.append('account_id', data.account_id);
			formData.append('project_id', state.project.id);
			formData.append('mode', state.project.mode);
			formData.append('file', state.file); 
			formData.append('sketch_id', data.sketch_id);
			formData.append('sketch_width', data.sketch_width);
			formData.append('sketch_height', data.sketch_height);
			formData.append('sketch_x', data.sketch_x);
			formData.append('sketch_y', data.sketch_y);
			formData.append('project_base64', state.project.base64);

			if(state.project.id == null){   
				response = await projectApi.createProjectTemplate(formData)  
				if(response.result){
					commit("setProjectId", { id:response.project_id, name:response.name})  
				}
			}
			else{
				response = await projectApi.upProjectTemplate(formData)  	 
			}

			return response;
		}, 
		async saveProjectConstructor({ state, commit }, data){

			let response = null;

			let formData = new FormData(); 
			formData.append('account_id', data.account_id);
			formData.append('project_id', state.project.id);
			formData.append('mode', state.project.mode);
			formData.append('file', state.file); 
			formData.append('project_base64', state.project.base64);  
			formData.append('elements', JSON.stringify(data.elements));  

			if(state.project.id == null){   
				response = await projectApi.createProjectConstructor(formData)  
				if(response.result){
					commit("setProjectId", { id:response.project_id, name:response.name})  
				}
			}
			else{
				response = await projectApi.upProjectConstructor(formData)  	 
			}

			return response;
		}		  
	},
}