let DetectionDevice = {
  detection(browserDetect){

      let type = "desktop";
      let os = null;
      let browser = null;
      let browser_v = null;
      
      let body = null;
      let css_device = "D";
      let css_os = null;
      let css_browser = null;

      let win = window,
      doc = document,
      docElem = doc.documentElement,
      b = doc.getElementsByTagName('body')[0],
      x = win.innerWidth || docElem.clientWidth || b.clientWidth,
      y = win.innerHeight|| docElem.clientHeight|| b.clientHeight; 
      
       
      if(/Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent)) {
        type = "mobile";
        css_device = "M";
      }
      
      browser = browserDetect.meta.name;
      browser_v = browserDetect.meta.version;
 
      css_browser = browser[0];

      let userAgent = window.navigator.userAgent,
          platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
          macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'];             

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
        css_os = "MOS"
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
        css_os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
        css_os = "W";
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
        css_os = "A";
      } else if (/Linux/.test(platform)) {
        os = 'Linux';
        css_os = "L";
      }   
   
      body = css_device+css_os+css_browser+browser_v;

      return {
        type, 
        os, 
        browser, 
        browser_v,
        x,
        y,
        css:{
          body
        }
      }
    }
}

export default DetectionDevice;
   
  
 

 