<template>
  <div class="modal-account-project" :style="device.type =='mobile' ? { 'height': '100%' } : { 'max-width': '717px', 'max-height': '650px', 'min-height': '300px'}">
    <div class="modal-close">
      <mdicon name="close-circle-outline" 
        class="modal-close-icon" 
        :style="device.type =='mobile' ? { 'right': '15px', 'top': '15px' } : {}"
        :width="30" 
        :height="30" 
        @click="close()"
      />
    </div>
    <div class="modal-content" style="flex-direction: column;position: relative;">     
      <div style="margin-bottom: 20px;">Для сохранения в галерею зажмите изображение</div>
      <div style="width:100%;">
        <img class="android_save_img" style="width:100%;" :src="url+'?cash='+Math.random()" id="view_context_menu"> 
      </div>  
    </div>
  </div>   
</template> 
<script> 
import { mapGetters } from 'vuex'
import { popModal } from "jenesius-vue-modal"
export default {  
  props:["url"],
  data () {
    return {
      el:null,
      tempo:3000,
    }
  },
  computed:{   
    ...mapGetters("device", { device:"device" }),
  },
  mounted(){ 
    this.viewContextMenu(); 
  },
  methods:{     
    close(){
      popModal()
    },  
    viewContextMenu(){ 
        // setTimeout(()=>{  
        //   var event1 = new MouseEvent('contextmenu');
        //   document.getElementById("view_context_menu").dispatchEvent(event1);  
        // }, 2000);

        // setTimeout(()=>{  
        //   var event2 = new Event('touchend');  
        //   document.getElementById("view_context_menu").dispatchEvent(event2);
        //   //alert(this.el);
        // }, 6000); 
    }, 
  }
}
</script>