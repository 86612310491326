import * as adminApi from '@/api/admin.js';

export default {
	namespaced: true,
	state: {
		sketchs:[], 
		colors:[],
		colors_all:[],
		filter:{
			sketchs:"all",
			color:'',
			access:"all"
		},
		page:1,
		total_items:0,
	},
	getters: { 
		admin_sketchs:state=>{
			return state
		},  
	},
	mutations: {
		setSketchs(state, data){ 
			state.sketchs = data.sketchs; 
			state.total_items = data.total_items; 
		}, 
		setColors(state, colors){ 
			state.colors = colors; 
		},  
		setColorsAll(state, colors){ 
			state.colors_all = colors; 
		},  
		setfilterSketchs(state, sketchs){ 
			state.filter.sketchs = sketchs; 
			state.page = 1; 
		},
		setfilterAccess(state, access){ 
			state.filter.access = access;
			state.page = 1; 
		},
		setfilterColor(state, color){ 
			state.filter.color = color; 
		},
		setPage(state, page){ 
			state.page = page; 
		},
	},
	actions: {    
		async sketchsGet({ state, commit }){ 
			await adminApi.sketchsGet({ filter:state.filter, page:state.page }).then((response)=>{
				if(response.result){
					commit('setSketchs', {sketchs:response.sketchs, total_items:response.total_items});	
					commit('setColorsAll', response.colors);	
				}
			});	  
		}, 
		async sketchsColorsGet({ commit }){ 
			await adminApi.sketchsColorsGet().then((response)=>{
				if(response.result){
					commit('setColors', response.colors);	
				}
			});	  
		}, 
		async sketchsColorCreate({ dispatch }, data){  
			let response = await adminApi.sketchsColorCreate(data); 
			if(response.result)
				dispatch('sketchsColorsGet');	 
			return response;
		},
		async sketchsColorUp({ dispatch }, data){  
			let response = await adminApi.sketchsColorUp(data); 
			if(response.result)
				dispatch('sketchsColorsGet');	 
			return response;
		}, 
		async sketchsColorDelete({ dispatch }, data){ 
			await adminApi.sketchsColorDelete(data).then((response)=>{
				if(response.result){
					dispatch('sketchsColorsGet');	
				}
			});	  
		}, 
		async sketchsCreate({ dispatch }, data){
			let response = await adminApi.sketchsCreate(data); 
			if(response.result)
				dispatch('sketchsGet');	 
			return response;
		},
		async sketchsUp({ dispatch }, data){
			let response = await adminApi.sketchsUp(data); 
			if(response.result)
				dispatch('sketchsGet');	 
			return response;
		}, 
		async sketchsDelete({ dispatch }, data){
			let response = await adminApi.sketchsDelete(data); 
			if(response.result)
				dispatch('sketchsGet');	  
		}, 
		setfilterSketchs({ commit, dispatch }, sketchs){
			commit('setfilterSketchs', sketchs);	
			dispatch('sketchsGet');
		},
		setfilterAccess({ commit, dispatch }, access){
			commit('setfilterAccess', access);	
			dispatch('sketchsGet');
		} 
	}, 
}