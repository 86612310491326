export default {
	namespaced: true,
	state: {
		browser:null, 
		browser_v:null, 
		os:null, 
		type:null, 
		x:null, 
		y:null, 
		style:{
			body_height:"100%",
			padding_bottom:"0px",
			container_width:"100%",
		},
		app:{
			install_event:null,
			install_status:false,
			ios_status:false
		},
		content:{
			load:false,
			text:null,
		}
	},
	getters: { 
		device:state=>{
			return state
		},  
	},
	mutations: {
		setDevice(state, data){ 
			state.browser = data.browser; 
			state.browser_v = data.browser_v; 
			state.os = data.os; 
			state.type = data.type; 
			state.x = data.x; 
			state.y = data.y;  
		}, 
		setAppInstallEvent(state, event){ 
			state.app.install_event = event; 
		},
		setAppInstallStatus(state, status){ 
			state.app.install_status = status; 
		},
		setAppIosStatus(state, status){ 
			state.app.ios_status = status; 
		},
		setBodyHeight(state, height){ 
			state.style.body_height = (state.y - height - 5)+"px"; 
		},
		setPaddingBottom (state, padding){ 
			//alert(padding);
			state.style.padding_bottom = padding+"px"; 
		}, 
		setContainerWidth (state, width){  
			state.style.container_width = width+"px"; 
		},  
		setContentLoad(state, data){ 
			state.content.load = data.status; 
			state.content.text = data.text; 
		},
	},
	actions: {
		installApp({ state, commit }){ 
			state.app.install_event.prompt() 
			state.app.install_event.userChoice.then((choice) => { 
				if(choice.outcome === 'accepted') 
					commit("setAppInstallStatus", false);
				else   
					commit("setAppInstallStatus", true);
			})
		},
		resizeHandler({ commit }) {  
			//this.setBodyHeight(document.querySelector(".header-app").clientHeight); 
			if(document.querySelector(".header-app") !== null) 
				commit("setBodyHeight", document.querySelector(".header-app").clientHeight);
			let sab = getComputedStyle(document.documentElement).getPropertyValue("--sab").replace('px', ''); //подтягиваем зону безопасного экрана bottom
			let bottom_device = isNaN(Number(sab)) ? 0 : Number(sab);    
			//this.setPaddingBottom(bottom_device);
			commit("setPaddingBottom", bottom_device);
			console.log("resizeHandler");
			if(document.getElementById("container") !== null){ 
				commit("setContainerWidth", document.getElementById("container").clientWidth); 
			} 
		},
	}, 
}