<template>   
 <div class="header-app">
  <div class="header-content" style="position: relative;">

    <on-boarding 
      v-if="account.view_onboarding.status && account.view_onboarding.element == 'back' && getRoute.to.name == 'account-photo'"
      step="photo" 
      element="back"
    ></on-boarding>
    <on-boarding 
      v-if="account.view_onboarding.status && account.view_onboarding.element == 'back' && getRoute.to.name == 'account-template'"
      step="template" 
      element="back"
    ></on-boarding>
    <on-boarding 
      v-if="account.view_onboarding.status && account.view_onboarding.element == 'back' && getRoute.to.name == 'account-constructor'"
      step="constructor" 
      element="back"
    ></on-boarding>
    <on-boarding 
      v-if="account.view_onboarding.status && account.view_onboarding.element == 'next' && getRoute.to.name == 'account-template'"
      step="template" 
      element="next"
    ></on-boarding>
    <on-boarding 
      v-if="account.view_onboarding.status && account.view_onboarding.element == 'next' && getRoute.to.name == 'account-constructor'"
      step="constructor" 
      element="next"
    ></on-boarding>

    <div style="width:35px;height:35px;">  
      <div 
      :style="account.view_onboarding.status && account.view_onboarding.element == 'back' ? { 'color': '#fff', 'z-index':10, 'position': 'relative' } : {'color': '#bf1921', 'cursor': 'pointer'}"
        v-if="header_app.link_back.status"
        @click="to({to:header_app.link_back.to, page:header_app.link_back.page})">
        <mdicon name="chevron-left" :width="35" :height="35" />  
      </div>  

    </div>
    <img :src="require('@/assets/logo.png')" style="width: 100px;">
    <div style="width:35px;height:35px;">
      <div
        :style="account.view_onboarding.status && account.view_onboarding.element == 'next' ? { 'color': '#fff', 'z-index':10, 'position': 'relative' } : {'color': '#bf1921', 'cursor': 'pointer'}"
        v-if="header_app.link_next.status"
        @click="to({to:header_app.link_next.to, page:header_app.link_next.page})">
          <mdicon name="chevron-right" :width="35" :height="35" />  
      </div>   
    </div>
  </div>
 </div> 
</template>
<script> 
import { mapGetters } from 'vuex'
import SelectModeConstructor from '@/views/account/components/select_mode_constructor.vue'
import AccountProject from '@/views/account/components/account_project.vue'
import GuestRegister from '@/views/account/components/guest_register.vue'
import { config, openModal } from "jenesius-vue-modal";
import onBoarding from "@/views/account/components/on_boarding.vue";

export default {
  computed:{    
    ...mapGetters("account", { account:"account" }),
    ...mapGetters("header_app", { header_app:"header_app" }),
    ...mapGetters("photo_object", { photo_object:"photo_object" }),
    ...mapGetters("getRoute", { getRoute:"getRoute" }),
  },
  methods:{ 
    to(data){  
      if(!this.account.view_onboarding.status){
        if(data.page == "account-photo"){
          config({ backgroundClose:false }) 
          openModal(SelectModeConstructor,{ account_id:this.account.id })
        }
        else if(data.page == "account-template" && data.to == null){
          config({ backgroundClose:false })
          if(this.account.id == 'demo') 
            openModal(GuestRegister, { type:"save" })
          else
            openModal(AccountProject)
        }
        else if(data.page == "account-constructor" && data.to == null){
          config({ backgroundClose:false })
          if(this.account.id == 'demo') 
            openModal(GuestRegister, { type:"save" })
          else
            openModal(AccountProject)
        }
        else
          this.$router.push(data.to);
      }
    }
  },
  components: { 
    onBoarding, 
  },  
}
</script> 