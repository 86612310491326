import { createStore } from 'vuex'
import device from './device';
import account from './account';
import getRoute from './getRoute';
import photo_object from './photo_object';
import header_app from './header_app';
import account_templates from './account_templates';
import account_constructor from './account_constructor';
import admin_users from './admin_users';
import admin_sketchs from './admin_sketchs';
import admin_push from './admin_push';
 
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    device,
    account,
    getRoute,
    header_app,
    photo_object,
    account_templates,
    account_constructor,
    admin_users,
    admin_sketchs,
    admin_push
  }
})
