<template>
	<div style="background: #fff;min-width: 200px;padding: 45px;border-radius: 5px;position: relative;">

    <div style="position: absolute; top:10px; right:10px;">
      <mdicon name="close-circle-outline" style="color:#bf1921; cursor: pointer;" @click="close()"/> 
    </div>

    <div style="display: flex;flex-direction: column;align-items: center;">
      
      <div style="margin-bottom: 22px;">Выберите режим работы</div>

      <div class="welcome-btn" @click="to({ name:'account-constructor', mode:'constructor' })">
        <mdicon name="wrench-outline" /> 
        Конструктор
      </div>

      <div class="welcome-btn" @click="to({ name:'account-template', mode:'template' })">
        <mdicon name="animation-outline" /> 
        Готовые решения
      </div>
    </div>

  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { popModal } from "jenesius-vue-modal"
  
export default { 
  props:["account_id"], 
  methods:{ 
    ...mapMutations("device", ["setContentLoad"]), 
    ...mapMutations("photo_object", ["setProjectMode"]),  
    close(){
      popModal()
    },
    to(data){ 
      popModal();
      this.setContentLoad({ status:false, text:null })
      this.setProjectMode({ mode:data.mode })
      this.$router.push({ name:data.name, params:{ id:this.account_id }}); 

    }, 
  }
}
</script>