import * as accountTeplatesApi from '@/api/account_tepmlates.js';

export default {
	namespaced: true,
	state: {
		w:100,
		h:100,
		w_end:null,
		h_end:null,
		x:0,
		y:0,
		templates:[],
		colors:[''],
		select_color: '',
		select_template:{
			id:null,
			url:null,
			base64:null
		},
		load_img_status:false,
	},
	getters: {
		account_templates:state=>{
			return state
		},  
	},
	mutations: {
		setTemplates(state, templates){ 
			state.templates = templates; 
		}, 
		setColors(state, colors){  
			state.colors = colors
		}, 
		setSelectColor(state, colors){  
			state.select_color = colors
		},
		setSelectTemplateUrl(state, data){  
			console.log(data);
			state.select_template.id = data.id
			state.select_template.url = data.url
		}, 
		setSelectTemplateBase64(state, base64){  
			state.select_template.base64 = base64 
		}, 
		setWH(state, data){  
			state.w = data.w
			state.h = data.h
		}, 
		setWHEnd(state, data){  
			state.w_end = data.w_end
			state.h_end = data.h_end
		}, 
		setXY(state, data){  
			state.x = data.x
			state.y = data.y
		},
		setLoadImgStatus(state, status){
			state.load_img_status = status
		},
	},
	actions: {    
		async templatesColorGet({ commit }){
			await accountTeplatesApi.templatesColorGet().then((response)=>{
				if(response.result)
					commit('setColors', response.colors);
			})	 			 
		},
		async templatesGet({ state, commit, rootGetters }){
			let code = state.select_color == '' ? "all" : state.select_color;
			let user_id = rootGetters['account/account'].id

			await accountTeplatesApi.templatesGet({ code, user_id }).then((response)=>{
				if(response.result)
					commit('setTemplates', response.templates);
			})	 			 
		},
		async templatesGetBase64({ state, commit }){

			let response = await accountTeplatesApi.templatesGetBase64({ id:state.select_template.id });
			if(response.result)
					commit('setSelectTemplateBase64', response.base64);

			return response;
		},  
		setSelectTemplateUrl({commit}, data){
			commit("setSelectTemplateUrl", data)
		},
		setWH({commit}, data){
			commit("setWH", data)
		},
		setWHEnd({commit}, data){
			commit("setWHEnd", data)
		},
		setXY({commit}, data){
			commit("setXY", data)
		}
	}, 
}