import server from '@/api/http';

export async function templatesColorGet(params){  
	let { data } = await server.get('templates/color/get', { params });
	return data;
}
export async function templatesGet(params){  
	let { data } = await server.get('templates/get', { params });
	return data;
} 
export async function templatesGetBase64(params){  
	let { data } = await server.get('templates/get/base64', { params });
	return data;
} 

 