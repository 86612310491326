import { createWebHistory, createRouter } from "vue-router";
import store from '../store'
import middlewarePipeline from './middlewarePipeline'
import token from './middleware/token' 
import guest from './middleware/guest' 
import auth from './middleware/auth'    
import account from './middleware/account';
import admin from './middleware/admin';

const routes = [
  {
    path: "/",
    name: "page-index",
    component: () => import('@/views/pages/page_index.vue'),
    meta: {  
      layout: 'auth',
      middleware: [
        token, guest
      ]  
    }, 
  }, 
  {
    path: "/register",
    name: "page-register",
    component: () => import('@/views/pages/page_register.vue'),
    meta: {  
      layout: 'auth',
      middleware: [
        token, guest
      ]  
    }, 
  }, 
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import('@/views/pages/password_reset.vue'),
    meta: {  
      layout: 'auth',
      middleware: [
        token, guest
      ]  
    }, 
  },
  {
    path: "/password-verify",
    name: "password-verify",
    component: () => import('@/views/pages/password_verify.vue'),
    meta: {  
      layout: 'auth',
      middleware: [
        token, guest
      ]  
    }, 
  }, 
  {
    name: 'account-index',
    path: '/account/:id',
    component: () => import('@/views/account/account_index.vue'), 
    children:[
      {
        name: 'account-welcome',
        path: 'welcome',
        component: () => import('@/views/account/account_welcome.vue'),
      }, 
      {
        name: 'account-photo',
        path: 'photo',
        component: () => import('@/views/account/account_photo.vue'),
      },
      {
        name: 'account-template',
        path: 'template',
        component: () => import('@/views/account/account_template.vue'),
      },
      {
        name: 'account-constructor',
        path: 'constructor',
        component: () => import('@/views/account/account_constructor.vue'),
      },
    ],
    meta: {  
      layout:"account", 
      middleware: [
        token, auth, account
      ],
    }
  },
  {
    name: 'admin-index',
    path: '/admin',
    component: () => import('@/views/admin/admin_index.vue'), 
    children:[
      {
        name: 'admin-users',
        path: 'users',
        component: () => import('@/views/admin/admin_users.vue'),
      }, 
      {
        name: 'admin-sketchs',
        path: 'sketchs',
        component: () => import('@/views/admin/admin_sketchs.vue'),
      }, 
      {
        name: 'admin-sketchs-colors',
        path: 'sketchs_colors',
        component: () => import('@/views/admin/admin_sketchs_colors.vue'),
      },
      {
        name: 'admin-push',
        path: 'push',
        component: () => import('@/views/admin/admin_push.vue'),
      }, 
    ],
    meta: {  
      layout:"admin", 
      middleware: [
        token, auth, admin
      ],
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: "404",
    component: () => import('@/views/pages/page_404.vue'),
    meta: {  
      layout: 'page', 
    }, 
  },    
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  store.dispatch("getRoute/setRoute", { to, from });

  if(!to.meta.middleware) return next()

  let params = to.params
  const middleware = to.meta.middleware
  const context = {
      to,
      from,
      next,
      store,
      params
  }
  return middleware[0]({
      ...context, 
      nextMiddleware: middlewarePipeline(context, middleware, 1)
  })

});

export default router;
